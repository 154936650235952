import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import DefaultLayout from '../layouts/default/defaultLayout'
import BlogPage from '../layouts/blog/BlogPage'
import PropTypes from 'prop-types'
import { withIntl } from '../i18n'
import { injectIntl } from 'react-intl'

class BlogIndex extends React.Component {
  static propTypes = {
    intl: PropTypes.object,
  }

  render() {
    const { intl } = this.props
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allMarkdownRemark.edges').filter(post => post.node.fields.slug.includes(`posts_${intl.locale}/`))
    // TODO: remove lang check when developing ENG version
    return (
      <DefaultLayout>
        <Helmet
          meta={[{ charset: 'utf-8' }]}
          title={this.props.intl.messages.blogPage.title + ' | ' + siteTitle}
        />
        {intl.locale !== 'en' && <BlogPage posts={posts} intl={intl} />}
      </DefaultLayout>
    )
  }
}

export default withIntl(injectIntl(BlogIndex))

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
